
const randomFromArray = (items) => {
    return items[Math.floor(Math.random()*items.length)];
}


export {
    randomFromArray,
}

