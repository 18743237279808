import React from "react"

import FeatureTags from "./featuretags"

// show a community's feature tags
const CommunityFeatureTags = ({community}) => {

  const allPossible = [
    'forum', 'chat', 'apply', 'pairing', 'perks', 'events', 'newsletter', 'jobs', 'paid', 'courses', 'tools', 'token'
  ]
  const features = allPossible.filter(a => community[a]);

  return (
    <FeatureTags features={features} />
  )
}

export default CommunityFeatureTags;

