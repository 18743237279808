import React, { useState } from "react"
import { Link } from "gatsby"

import {
  CalendarIcon,
  ChevronRightIcon,
  UsersIcon,
  UserGroupIcon,
  CollectionIcon,
  ClipboardListIcon,
  SparklesIcon,
  TagIcon,
  StarIcon,
  EyeIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  // TrophyIcon,
} from '@heroicons/react/outline'

import { unslugify, readableNumber } from '../../utils/text'
import { randomFromArray } from '../../utils/random'

import Image from "../common/image"
import withLocation from "../common/withlocation"

import CommunityFeatureTags from "../feature/communityfeaturetags"
import CommunityStarRating from "./starrating"

// import "../styles/communitiestable.css"

const CommunitiesTable = ({
  communities, 
  showPlatform, 
  showTopics, 
  linkModal,
  showCount,
  showVisitors,
  showRankings,
  queryParams,
  // splitByDates,
}) => {
  const defaultIncrement = 35;
  const [countToShow, setCountToShow] = useState(showCount || defaultIncrement);  // user expands manually
  const communitiesToShow = communities.slice(0, countToShow);

  const showStats = showVisitors || (queryParams.stats && queryParams.stats === '1');
  
  return (
    <React.Fragment>
      <ul className="divide-y divide-gray-200 text-left">
        {communitiesToShow.map((c, index) => (
          <li key={c.slug}>
            <Link to={`/communities/${c.slug}/`}
              className="block hover:bg-gray-50 group"
              state={{
                modal: linkModal || false,
                // nextCommunity: communities[index + 1],
              }}
            >
              <div className="py-4 px-2 sm:px-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex items-center">
                  
                  <div className="p-4 pl-0 flex-shrink-0 flex-grow-0 relative">
                    {showRankings && index < 10 ? (
                      <div className="hidden sm:block absolute py-1 px-3 top-10 right-24 rounded-full group-hover:bg-gray-50 text-gray-300 text-lg font-bold">#{index + 1}</div>
                    ) : ''}
                    {c.logo ? (
                      <img className="h-20 w-20 object-contain rounded-md object-left sm:object-center" src={c.logo} alt={`${c.name} logo`} width="70" height="42" loading="lazy" />
                    ) : (
                      <Image className="h-20 w-20 object-contain opacity-50 object-left sm:object-center" fileName="icon.png" alt="Community Placeholder" width="70" height="42" loading="lazy"/>   
                    )}
                  </div>
                  <div className="mr-4">
                    <div className="flex text-sm">
                      <h3 className="font-medium text-sm text-rose-500 truncate max-w-sm">{c.name}</h3>
                      {c.platform && showPlatform && c.platform !== 'independent' ? (
                        <p className="ml-1 flex-shrink-0 font-normal text-gray-400"> on {c.platform.split(',').join(" and ")}</p>
                      ) : ''}
                      {c.showPromotedTag ? (
                        <span className="ml-2 text-xs bg-yellow-400 font-semibold text-white rounded-md px-2 py-0.5">Featured</span>
                      ) : ''}
                    </div>
                    {showTopics && c.topics && c.topics.length ? (
                      <div className="flex items-center align-start font-normal">
                        <p className="text-xs text-gray-400">
                          {c.topics.split(',').map(t => unslugify(t)).join(', ')}
                        </p>
                      </div>
                    ) : ''}

                    {/*{c.reviewCount !== null && c.reviewCount !== null && c.reviewCount !== undefined && c.reviewCount !== undefined && c.reviewCount !== "0" ? (
                      <div className="flex items-center text-sm mt-2">
                        <CommunityStarRating community={c} />
                      </div>
                    ) : ''}*/}
                    {c.description ? (
                      <div className="mt-2 text-sm text-gray-600 line-clamp-4">
                        {c.description}
                      </div>
                    ) : ''}

                    {c.gold && c.reviewsPositive ? (
                      <div className="mt-2 text-sm text-gray-600">
                        
                        <div className="line-clamp-2 text-gray-400 text-sm">
                          "{randomFromArray(c.reviewsPositive.split('||'))}"
                        </div>

                        {/*<div className="flex items-center">
                          {[1,2,3,4,5].map((s, index) => (
                            <svg key={s} xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 text-yellow-400 flex-shrink-0`} fill="#fef3c7" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                            </svg>
                          ))}
                        </div>*/}
                      </div>
                    ) : ''}
                  </div>
                  <div className="block sm:inline mt-4 text-sm sm:w-48 text-gray-600 flex-shrink-0 sm:mt-0 sm:ml-auto space-y-1">
                    {c.reviewCount !== null && c.reviewCount !== null && c.reviewCount !== undefined && c.reviewCount !== undefined && c.reviewCount !== "0" ? (
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                        </svg>

                        <CommunityStarRating community={c} />
                      </div>
                    ) : ''}
                    {c.members ? (
                      <div className="flex items-center">
                        <UserGroupIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" aria-hidden="true" />
                        <p>
                          {readableNumber(c.members)} members
                        </p>
                      </div>
                    ) : ''}

                    {/*{false && showTopics && c.topics && c.topics.length ? (
                      <div className="flex items-center align-start">
                        <ClipboardListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" aria-hidden="true" />
                        <p>
                          {c.topics.split(',').map(t => unslugify(t)).join(', ')}
                        </p>
                      </div>
                    ) : ''}*/}

                    <div className="flex align-start text-xs">
                      <TagIcon className="mt-0.5 flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" aria-hidden="true" />
                      <CommunityFeatureTags community={c} />
                    </div>
                    {showStats ? (
                      <div className="flex items-center space-x-2">
                        {(c.sponsored || c.promoted) ? (
                          <CurrencyDollarIcon className="flex-shrink-0 h-5 w-5 text-yellow-400" aria-hidden="true" />
                        ) : c.gold ? (
                          <SparklesIcon className="flex-shrink-0 h-5 w-5 text-yellow-400" aria-hidden="true" />
                        ) : (
                          <ChartBarIcon className="flex-shrink-0 h-5 w-5 text-gray-300" aria-hidden="true" />
                        )}

                        <p className="text-xs text-gray-500">
                          {c.redirects} clicks
                        </p>
                        <p className="text-xs text-gray-500">
                          {c.visitors} visitors
                        </p>
                      </div>
                    ) : ''}
                  </div>
                </div>
                {/*<div className="ml-5 flex-shrink-0">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>*/}
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {communitiesToShow.length < communities.length ? (
        <div className="p-4 text-center text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
          onClick={() => setCountToShow(countToShow + defaultIncrement)}
        >
          {communities.length - communitiesToShow.length} more {communities.length - communitiesToShow.length === 1 ? 'community' : 'communities'} →
        </div>
      ) : ''}
      {communities.length === 0 ? (
        <div className="p-8 text-center text-gray-600 font-semibold opacity-75 h-96 cursor-pointer"
          // onClick={() => setCountToShow(countToShow + defaultIncrement)}
        >
          No communities that meet these filters
        </div>
      ) : ''}
    </React.Fragment>
  )

}


export default withLocation(CommunitiesTable);