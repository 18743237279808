import React from "react"

import FeatureIcon from "./featureicon"

import { getHexCode } from '../../utils/colors'
import { unslugify } from '../../utils/text'

// tags for features
const FeatureTags = ({features}) => {

  const getFeatureLabel = (feature) => {
    return unslugify(feature)
  }

  return (
    <div className="flex flex-wrap mt-0.5">
      {features.map(f => (
        <div key={f} className="py-0.5 px-1 mr-0.5 mb-0.5 bg-rose-400 rounded-sm text-white flex items-center">
          <FeatureIcon feature={f} className="h-3 w-3 mr-1 flex-shrink-0"/>
          <div>{getFeatureLabel(f)}</div>
        </div>
      ))}
    </div>
  )
}

export default FeatureTags;

